<template>
  <el-dialog
    top="10vh"
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    custom-class="dialog-warpper"
    width="80%"
  >
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
      <el-card>
        <div slot="header">试卷信息</div>

        <el-descriptions :column="1" border>
          <el-descriptions-item label="考试时间">
            <template slot="label">
              <!-- <i class="el-icon-user"></i> -->
              <span class="requireSyb">*</span>
              考试时间
            </template>

            <el-form-item prop="examDate">
              <el-date-picker
                style="width: 100%"
                v-model="dataForm.examDate"
                value-format="yyyy-MM"
                type="month"
                placeholder="请选择月份"
              >
              </el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="试卷标题">
            <template slot="label">
              <span class="requireSyb">*</span>
              试卷标题
            </template>

            <el-form-item prop="title">
              <el-input
                v-model="dataForm.title"
                placeholder="试卷标题"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="考试时长(分)">
            <template slot="label">
              <span class="requireSyb">*</span>
              考试时长(分)
            </template>

            <el-form-item prop="examDuration">
              <el-input
                v-model="dataForm.examDuration"
                placeholder="考试时长(分)"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="试卷总分">
            <template slot="label">
              <span class="requireSyb">*</span>
              试卷总分
            </template>

            <el-form-item prop="examScore">
              <el-input
                v-model="dataForm.examScore"
                placeholder="试卷总分"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="及格分数">
            <template slot="label">
              <span class="requireSyb">*</span>
              及格分数
            </template>

            <el-form-item prop="passScore">
              <el-input
                v-model="dataForm.passScore"
                placeholder="及格分数"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="考核主题">
            <template slot="label">
              <span class="requireSyb">*</span>
              考核主题
            </template>

            <el-form-item prop="peixunTitle">
              <el-input
                v-model="dataForm.peixunTitle"
                placeholder="考核主题"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="考核内容">
            <template slot="label">
              <span class="requireSyb">*</span>
              考核内容
            </template>

            <el-form-item prop="peixunContent">
              <el-input
                v-model="dataForm.peixunContent"
                placeholder="考核内容"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 5px">
        <div slot="header">题目集</div>

        <el-form-item prop="questionIds">
          <el-button
            type="primary"
            @click="chickSelectQuestion"
            style="margin-bottom: 5px"
          >
            点击选择题目
          </el-button>

          <el-table :data="questionArr" border size="mini" style="width: 100%">
            <el-table-column
              prop="id"
              header-align="center"
              align="center"
              width="200"
              label="ID"
            >
            </el-table-column>
            <el-table-column
              prop="userName"
              header-align="center"
              align="center"
              label="发布者"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              header-align="center"
              align="center"
              label="题目类型"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.type == 0">单选题</span>
                <span v-if="scope.row.type == 1">多选题</span>
                <span v-if="scope.row.type == 2">判断题</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="title"
              header-align="center"
              align="left"
              label="题目"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="left"
              label="分数"
              width="120"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row.score"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-card>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>

    <!-- 选题弹窗 -->
    <select-question
      v-if="selectQuestionVisible"
      ref="selectQuestion"
      @selectselectQuestionArr="selectselectQuestionArr"
    ></select-question>
  </el-dialog>
</template>

<script>
import SelectQuestion from "./selectQuestion.vue";
export default {
  components: {
    SelectQuestion,
  },
  data() {
    return {
      selectQuestionVisible: false,
      questionArr: [
        {
          id: "",
          uid: "",
          pid: "",
          title: "",
          type: "",
          answers: "",
          solution: "",
          createDate: "",
          updateDate: "",
          isDeleted: "",
          score: "",
        },
      ], // 题目数组
      visible: false,
      dataForm: {
        id: 0,
        cid: "",
        title: "",
        examDate: "",
        examScore: "",
        examDuration: "",
        questionIds: "",
        questionScore: "",
        peixunTitle: "",
        peixunContent: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        passScore: "",
        score: "",
      },
      dataRule: {
        peixunTitle: [
          { required: true, message: "考试主题不能为空", trigger: "blur" },
        ],
        peixunContent: [
          { required: true, message: "考试主题不能为空", trigger: "blur" },
        ],
        examDuration: [
          { required: true, message: "考试时长不能为空", trigger: "blur" },
        ],
        examDate: [
          { required: true, message: "月份不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "试卷标题不能为空", trigger: "blur" },
        ],
        examScore: [
          { required: true, message: "试卷总分不能为空", trigger: "blur" },
        ],
        passScore: [
          { required: true, message: "及格分数不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 点击选择题目
    chickSelectQuestion() {
      this.selectQuestionVisible = true;
      this.$nextTick(() => {
        this.$refs.selectQuestion.init(this.questionArr);
      });
    },
    // 子组件返回的题目id集
    selectselectQuestionArr(selectQuestionArr) {
      this.questionArr = selectQuestionArr;
      for (let i = 0; i < this.questionArr.length; i++) {
        this.questionArr[i].score = parseInt(
          this.dataForm.examScore / this.questionArr.length
        );
      }
    },
    init(id) {
      this.questionArr = [];
      this.tableData = [];
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.exam.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              let scoreArr = this.dataForm.questionScore.split("_");
              for (let i = 0; i < this.dataForm.questions.length; i++) {
                this.$set(this.dataForm.questions[i], "score", scoreArr[i]);
              }
              this.questionArr = this.dataForm.questions;
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let count = 0;
          for (let i = 0; i < this.questionArr.length; i++) {
            count += parseInt(this.questionArr[i].score);
          }
          console.log(count);
          if (count != this.dataForm.examScore) {
            this.$message.error("题目分数之和与试卷总分不相同，提交失败");
            return;
          }

          let requestDate = this.dataForm;

          // 题目id集
          requestDate.questionIds = "";
          // 题目分数集
          requestDate.questionScore = "";
          for (let i = 0; i < this.questionArr.length; i++) {
            requestDate.questionIds =
              requestDate.questionIds + this.questionArr[i].id + "_";
            requestDate.questionScore =
              requestDate.questionScore + this.questionArr[i].score + "_";
          }
          requestDate.questionIds = requestDate.questionIds.substring(
            0,
            requestDate.questionIds.length - 1
          );
          requestDate.questionScore = requestDate.questionScore.substring(
            0,
            requestDate.questionScore.length - 1
          );

          if (this.dataForm.id) {
            // 修改
            this.$http.exam.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.exam.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-descriptions {
    .el-descriptions-item__label {
      width: 120px;
      text-align: center;
      color: #606266;
    }
    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>