<template>
  <el-dialog
    class="com-dialog"
    :modal="false"
    width="70%"
    title="选择题目"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <div class="mod-config">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <el-input
            v-model="dataForm.keyword"
            placeholder="输入关键字搜索"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.type"
            clearable
            placeholder="按题目类型筛选"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="dataList"
        row-key="id"
        ref="multipleTable"
        border
        size="medium"
        v-loading="dataListLoading"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column
          prop="id"
          header-align="center"
          align="center"
          width="200"
          label="ID"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          header-align="center"
          align="center"
          label="发布者"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          header-align="center"
          align="center"
          label="题目类型"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">单选题</span>
            <span v-if="scope.row.type == 1">多选题</span>
            <span v-if="scope.row.type == 2">判断题</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          header-align="center"
          align="left"
          label="题目"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: center; margin-top: 10px"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 1000, 10000]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: block; text-align: center; margin-top: 10px"
      >
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      // 题目类型
      typeOptions: [
        {
          value: 0,
          label: "单选题",
        },
        {
          value: 1,
          label: "多选题",
        },
        {
          value: 2,
          label: "判断题",
        },
      ],
      // 选择的题目集
      selectQuestionArr: [],
      // 用于取消时恢复最初状态
      selectQuestionArrCopy: [],
      dataForm: {
        keyword: "",
        type: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
    };
  },
  methods: {
    // 多选事件
    handleSelectionChange(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        arr.push(value[i]);
      }
      localStorage.setItem("selectQuestionArr", JSON.stringify(arr));
    },
    // 初始化
    init(selectQuestionArr) {
      this.visible = true;
      localStorage.setItem(
        "selectQuestionArr",
        JSON.stringify(selectQuestionArr)
      );
      this.selectQuestionArr = selectQuestionArr;
      this.selectQuestionArrCopy = selectQuestionArr;
      this.getDataList();
    },
    // 提交，把选择的题目id集返回给父组件
    dataFormSubmit() {
      this.selectQuestionArr = JSON.parse(
        localStorage.getItem("selectQuestionArr")
      );

      this.selectQuestionArr.forEach((newItem) => {
        let score = 0;
        this.selectQuestionArrCopy.forEach((oldItem) => {
          if (newItem.id == oldItem.id) {
            score = oldItem.score;
          }
        });
        this.$set(newItem, "score", score);
      });

      this.$emit("selectselectQuestionArr", this.selectQuestionArr);
      this.visible = false;
    },
    cancel() {
      localStorage.setItem(
        "selectQuestionArr",
        JSON.stringify(this.selectQuestionArrCopy)
      );
      this.$emit("selectselectQuestionArr", this.selectQuestionArrCopy);
      this.visible = false;
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      this.$http.question.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
          if (this.selectQuestionArr.length > 0) {
            this.$nextTick(() => {
              this.dataList.forEach((newRow) => {
                let flag = false;
                this.selectQuestionArr.forEach((oldRowId) => {
                  if (oldRowId.id == newRow.id) {
                    flag = true;
                  }
                });
                if (flag) {
                  this.$refs.multipleTable.toggleRowSelection(newRow, true);
                } else {
                  this.$refs.multipleTable.toggleRowSelection(newRow, false);
                }
              });
            });
          } else {
            this.$refs.multipleTable.clearSelection();
          }
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-dialog {
      box-shadow: 0 0 15px 0px rgba($color: #000, $alpha: .3);
    }
}
</style>